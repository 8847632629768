import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";

export default function LoadingMessage() {
    return (
        <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                Loading...
            </Typography>
            <LinearProgress/>
        </Container>
    );
}

