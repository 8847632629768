import Container from "@material-ui/core/Container";
import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import {firestoreStudentsPath, initFirebase} from "./FirebaseUtil";
import { useHistory } from "react-router-dom";

initFirebase();
let db = firebase.firestore();

function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

export default function Setup() {

    const [studentNames, setStudentNames] = React.useState('');
    const [statusMsg, setStatusMsg] = React.useState('');
    const history = useHistory();

    async function saveToFirebase(namesList) {


        function rand10Digits() {
            return Array(10).fill().map(
                () => Math.floor(Math.random() * 10).toString()).join('');
        }

        try {
            let sessionId = rand10Digits();
            let editCode = rand10Digits();
            let studentsPath = firestoreStudentsPath(sessionId);

            // Set the students list
            // From https://stackoverflow.com/a/54323908/76295
            let batch = db.batch();

            console.log('Setting session doc');
            batch.set(db.collection('sessions').doc(sessionId), {
                createdTime:firebase.firestore.FieldValue.serverTimestamp(),
                editCode
            });

            // console.log('Setting doc');
            // await db.collection('sessions').doc(sessionId).set({
            //     createdTime:firebase.firestore.FieldValue.serverTimestamp(),
            //     editCode
            // });

            console.log('Setting session names');
            namesList.forEach((name, idx) => {
                let doc = {name,
                    ordinal:idx,
                    answer:''};
                let docRef = db.collection(studentsPath).doc(); //automatically generate unique id
                batch.set(docRef, doc);
            });
            console.log('Committing');
            await batch.commit();
            console.log('Finished Committing');
            setStatusMsg('');
            history.push(`/dashboard/${sessionId}/${editCode}`);
        }catch(error) {
            console.error(error);
            setStatusMsg("Error: " + error);
        }

    }

    async function queryAllSessions() {
        try {
            let querySnapshot = await db.collection('sessions').get();

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
            });

            setStatusMsg('Received collection result' + querySnapshot.size);
        } catch (e) {
            setStatusMsg('Error: ' + e);
        }
    }

    async function handleClickSubmit() {
        if (studentNames === '') {
            setStatusMsg('No names entered');
            return;
        }
        let namesList = studentNames.replace(/\n/g, ",")
            .split(",")
            .map(name => name.trim())
            .filter(name => name !== '');
        if (hasDuplicates(namesList)) {
            setStatusMsg('List cannot contain duplicate names');
            return;
        }

        setStatusMsg('Submitting...');
        await saveToFirebase(namesList);
        // await queryAllSessions();
    }


    return (
        <Container maxWidth="sm">
            <Typography variant="h3" gutterBottom>
                My Students
            </Typography>
            <Typography variant="body1" gutterBottom>
                Enter the names of each person who will answer your questions, separated by comma or new line
            </Typography>
            <TextField style={{paddingTop:"20px", width:"100%"}}
                multiline
                rows={10}
                       placeholder="eg. David, Kim, Rajesh"
                variant="outlined"
                autoFocus={true}
                onChange={(e) => {
                    setStudentNames(e.target.value);
                    setStatusMsg('');
                }}

            />
            <Box display="flex" flexDirection="row" style={{paddingTop:'20px', alignItems:'center'}}>
                <Button variant="contained"
                        color="primary"
                        onClick={handleClickSubmit}
                        style={{marginRight:'20px'}}
                >
                    Submit
                </Button>
                {/*<CircularProgress size={30}/>*/}
                <Typography variant="body1" style={{marginLeft:'10px'}}>
                    {statusMsg}
                </Typography>

            </Box>
            <Typography variant="body2" style={{marginTop:20, color:"#AAAAAA"}}>
                Version 1.21
            </Typography>
        </Container>
    );
}