import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";


export default function StudentList({studentsMap, onComplete}) {
    console.log('Student list params: ' , studentsMap);
    let sortedList = Object.keys(studentsMap)
        .sort((a, b)=>studentsMap[a].name.localeCompare(studentsMap[b].name));

    const [selection, setSelection] = React.useState(null);

    return (
        <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                Select Your Name
            </Typography>
            <div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selection}
                onChange={(e) => {
                    setSelection(e.target.value);
                }}
                style={{minWidth:'260px'}}
            >
                {
                    sortedList.map((id)=><MenuItem value={id}>{studentsMap[id].name}</MenuItem>)
                }
            </Select>
            </div>
            <Button variant="contained"
                    color="primary"
                    onClick={()=>onComplete(selection)}
                    style={{marginTop:'20px'}}
            >
                Continue
            </Button>

        </Container>
            );
}