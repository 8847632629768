import React, {useEffect} from "react";
import LoadingMessage from "./LoadingMessage";
import firebase from "firebase";
import {firestoreStudentsPath, initFirebase, toDocMap} from "./FirebaseUtil";
import {ErrorMessage} from "./ErrorMessage";
import Answers from "./Answers";
import {useHistory} from "react-router-dom";

initFirebase();

export default function Dashboard ({location}) {

    const history = useHistory();
    let locationComponents = location.pathname.split('/');
    let editCode = locationComponents.pop();
    let sessionId = locationComponents.pop();
    console.log('Session:' , sessionId);
    console.log('Edit Code:' , editCode);


    const [students, setStudents] = React.useState(null);
    const [error, setError] = React.useState(null);

    async function load() {
        let db = firebase.firestore();

        try {

            console.log('checking session edit code');
            let sessionDoc = await db.collection('sessions').doc(sessionId).get();
            if (sessionDoc.get('editCode') !== editCode) {
                throw new Error("Failed to find matching session");
            }
            let studentsSnapshot = await db.collection(firestoreStudentsPath(sessionId)).get();
            let studentsMap = toDocMap(studentsSnapshot);
            setStudents(studentsMap);

            // let sessionId = userResult.get('sessionId');
            // let studentsSnapshot = await db.collection(firestoreStudentsPath(sessionId)).get();
        } catch (error) {
            console.error(error);
            setError("Error getting document:" + error);
        }


    }

    useEffect(()=>{load();}, []);
    if (error) {
        return <ErrorMessage msg={error}/>;
    }
    if (students) {
        return <Answers sessionId={sessionId} studentsMap={students} onClose={()=>history.push("/")} />;
    }

    return (
        <LoadingMessage/>
    );

}