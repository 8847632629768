import Container from "@material-ui/core/Container";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import AnswersTable from "./AnswersTable";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import {firestoreStudentsPath, toDocMap} from "./FirebaseUtil";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import {FormControlLabel, FormGroup, Switch} from "@material-ui/core";

function simplify(str) {
    str = str.toLowerCase();
    // Add spaces around any parentheses
    str = str.replace(/[(){}[\].]+/g, ' $& ');
    // Add spaces around any non-word, non-whitespace characters (eg. +=, -, etc)
    str = str.replace(/[^\w\s]+/g, ' $& ');
    // Compress all groups of spaces to one
    return str.replace(/[\s,;]+/g, ' ').trim();
}


function match(ans, sol) {
    return simplify(ans) === simplify(sol);
}


function lockSwitchLabel(locked, lockChangePending) {
    if (lockChangePending) {
        return locked ? "Unlocking..." : "Locking...";
    }
    else {
        return locked ? "Answers Locked" : "Answers Unlocked";
    }
}

export default function Answers({studentsMap, onClose, sessionId}) {

    const [answers, setAnswers] = React.useState(studentsMap);
    const [statusMsg, setStatusMsg] = React.useState('');
    const [solution, setSolution] = React.useState('');
    const [filterIndex, setFilterIndex] = React.useState(0);
    const [hideInactive, setHideInactive] = React.useState(false);
    const [lockChangePending, setLockChangePending] = React.useState(false);


    useEffect(()=> {
        let db = firebase.firestore();
        db.collection(firestoreStudentsPath(sessionId))
            .onSnapshot({
                // Listen for document metadata changes
                includeMetadataChanges: true
            }, (querySnapshot) => {
                if (querySnapshot.metadata.hasPendingWrites) {
                    // Ignore local changes
                    return;
                }
                setAnswers(toDocMap(querySnapshot));
            }, (error) => {
                setStatusMsg("Error: "+ error);
            });
    }, []);
    let qNumFieldName= 'currentQuestionNum';

    // From https://stackoverflow.com/a/24922761/76295:
    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    async function getCurrentQuestionNum(db) {
        let sessionRef = await db.collection('sessions').doc(sessionId);
        let sessionDoc = await sessionRef.get();
        let sessionData = sessionDoc.data();
        let qNum = sessionDoc.get(qNumFieldName);
        console.log('qnum-internal', qNum);
        qNum = (qNum === undefined) ? 1 : qNum + 1;
        return {sessionRef, qNum, sessionData};
    }

    async function handleClickHistory() {
        setStatusMsg('Getting history...');
        let db = firebase.firestore();
        let {qNum, sessionData} = await getCurrentQuestionNum(db);
        console.log('qnum', qNum);
        let cells = [['Name'],['Solution']];

        for (let i = 1; i <= qNum; i++) {
            cells[0].push('Question ' + i);
            cells[1].push(i === qNum ? (solution || '') : sessionData['solution-' + i]);
        }


        for (let id in answers) {
            let row = [];

            function pushAnswerAndStatus(studentData, answer, solution) {
                if (answer !== '' && solution !== undefined && match(answer, solution)) {
                    answer = answer + "\n--{correct}--";
                }
                row.push(answer);
            }
            cells.push(row);
            let studentDoc = await db.collection(firestoreStudentsPath(sessionId)).doc(id).get();
            let studentData = studentDoc.data();
            row.push(studentData.name);
            for (let i = 1; i < qNum; i++) {
                let solution = sessionData['solution-' + i];
                pushAnswerAndStatus(studentData, studentData['answer-' + i], solution);
            }
            pushAnswerAndStatus(studentData, studentData['answer'], solution);
        }


        setStatusMsg('');

        exportToCsv('history.csv', cells);
    }

    function handleClickClear() {
        performDbAction('Clearing answers...', 'Are you sure you want to clear all answers?',
            async (db)=>{
                let {sessionRef, qNum} = await getCurrentQuestionNum(db);
                console.log('qnum', qNum);

                let batch = db.batch();
            for (let id in answers) {
                let ref = db.collection(firestoreStudentsPath(sessionId)).doc(id);
                let data = {answer:'', fromTeacher:true, locked:false};
                data[`answer-${qNum}`] = answers[id].answer;
                batch.update(ref, data);
            }
            let baseData = {};
            baseData[qNumFieldName] = qNum;
            baseData[`solution-${qNum}`] = solution;
            batch.update(sessionRef, baseData);
            await batch.commit();
            resetFilterAndSolution();
        });
    }

    async function handleToggleLocked() {
        if (lockChangePending) {
            return;
        }

        try {
            setLockChangePending(true);
            let db = firebase.firestore();
            let batch = db.batch();
            for (let id in answers) {
                let ref = db.collection(firestoreStudentsPath(sessionId)).doc(id);
                batch.update(ref, {locked: !locked});
            }
            await batch.commit();
        } catch (error) {
            console.log(error);
            setStatusMsg('Error: ' + error);
        }
        finally {
            setLockChangePending(false);
        }

    }

    async function performDbAction(actionDesc, confirmMsg, action) {
        if (!window.confirm(confirmMsg)) {
            return;
        }
        setStatusMsg(actionDesc);
        try {
            let db = firebase.firestore();
            await action(db);
            setStatusMsg('');
        } catch (error) {
            console.log(error);
            setStatusMsg('Error: ' + error);
        }
    }

    const [targetQ, setTargetQ] = React.useState(0);

    let maxHistQ = 0;

    let answersSorted = Object.values(answers).sort((a, b)=>a.name.localeCompare(b.name));
    answersSorted.forEach(ans => {
        while (ans['answer-' + (maxHistQ + 1)] !== undefined) {
            maxHistQ++;
        }
    });
    let nowInSecs = Math.floor(Date.now() / 1000);
    answersSorted.forEach(ans => {
        let lastActivity = ans.lastClientActivity;
        ans.isActive = lastActivity !== undefined && (nowInSecs - lastActivity.seconds) < 60 * 60;
    });
    let locked = Object.values(answers).every(ans => ans.locked);




    let linkUrl = window.location.href.split('#')[0] + "#/s/" + sessionId;

    let all = (_ => true);
    let answered = (ans => ans !== '');
    let unanswered = (ans => !answered(ans));
    let correct = (ans => answered(ans) && match(ans, solution));
    let incorrect = (ans => answered(ans) && !match(ans, solution));

    let answerFieldName = (targetQ === 0) ? "answer" : "answer-" + targetQ;

    let filters = [
        {name: hideInactive ? 'All Active' : 'Show All', filter:all},
        {name:'No Answer', filter:unanswered},
        {name:'Any Answer', filter:answered},
        {name:'Correct Answer', filter:correct, requiresSolution:true},
        {name:'Incorrect Answer', filter:incorrect, requiresSolution:true}
    ];
    filters.forEach((f) => {
        if (solution === '' && f.requiresSolution) {
            return;
        }
        f.result = answersSorted.filter(a => {
            if (hideInactive && !a.isActive) {
                return false;
            }
            return f.filter(a[answerFieldName]);
        });
        f.name += ' (' + f.result.length + ')';

    });
    let currentFilter = filters[filterIndex];


    function promptForSolution() {
        return window.prompt('Enter the solution to compare answers against', solution);
    }


    function selectFilterIndex(idx) {
        if (solution === '' && filters[idx].requiresSolution) {
            let newSolution = promptForSolution();
            if (newSolution === null || newSolution === '') {
                return;
            }
            setSolution(newSolution);
        }
        setFilterIndex(idx);
    }

    function setSolutionClicked() {
        let newSolution = promptForSolution();
        if (newSolution === null) {
            return;
        }
        setSolution(newSolution);
        if (newSolution === '' && currentFilter.requiresSolution) {
            setFilterIndex(0);
        }
    }

    function resetFilterAndSolution() {
        setSolution('');
        setFilterIndex(0);
    }

    function nextTargetQ() {
        setTargetQ(targetQ === maxHistQ ? 0 : targetQ + 1);
        resetFilterAndSolution();
    }

    function prevTargetQ() {
        setTargetQ(targetQ === 0 ? maxHistQ : targetQ - 1);
        resetFilterAndSolution();
    }


    const [onboarded, setOnboarded] = React.useState(filters[2].result.length === 0 ? undefined : true);


    return (
        <Container maxWidth="lg">
            <Box display="flex" flexDirection="row" style={{alignItems:'center', marginBottom:'5px'}} flexWrap="wrap">
                <Typography variant="h3" style={{marginRight:'20px'}}>
                    Dashboard
                </Typography>
                {onboarded && <div>
                    <Button variant="contained"
                            color="primary"
                            onClick={handleClickClear}
                            style={{marginRight:20,marginBottom:5,marginTop:5}}
                    >
                        New Question
                    </Button>
                    <Button variant="contained"
                            onClick={handleClickHistory}
                            style={{marginRight:20,marginBottom:5,marginTop:5}}
                            disableElevation
                    >
                        History
                    </Button>
                    <Button variant="contained"
                            onClick={onClose}
                            style={{marginRight:20,marginBottom:5,marginTop:5}}
                            disableElevation
                    >
                        New Session
                    </Button>
                    <Button variant="contained"
                            onClick={prevTargetQ}
                            style={{marginRight:5,marginBottom:5,marginTop:5}}
                            disableElevation
                    >
                        &lt;
                    </Button>
                    <Button variant="contained"
                            onClick={nextTargetQ}
                            style={{marginRight:20,marginBottom:5,marginTop:5}}
                            disableElevation
                    >
                        &gt;
                    </Button>

                </div>}
                {onboarded &&
                <Typography variant="body1" style={{flex:1, textAlign:"left", whiteSpace:"nowrap"}}>
                    {statusMsg || ((targetQ === 0 ? (maxHistQ + 1) : targetQ) + ' of ' + (maxHistQ + 1))}
                </Typography>}

            </Box>
            <Box display="flex" flexDirection="row" style={{alignItems:'center', marginBottom:'5px'}} flexWrap="wrap">
                <Typography variant="body1" style={{marginRight:10}}>
                    Student Link:
                </Typography>
                <Typography variant="body1" style={{marginRight:20}}>
                    <a href={linkUrl} target="_blank">{linkUrl}</a>
                </Typography>
                {onboarded &&
                <FormGroup>
                    <FormControlLabel control={<Switch
                        checked={locked}
                        defaultChecked
                        onChange={handleToggleLocked}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color={'primary'}
                    />} label={lockSwitchLabel(locked, lockChangePending)} />
                </FormGroup>
                }
            </Box>

            {
                !onboarded &&
                <div>
                    <Typography variant="body1" style={{paddingTop:10, paddingBottom:20}}>
                        To get started, give this link to your students so they can submit their answers.
                    </Typography>
                    <Button variant="contained"
                            color="primary"
                            onClick={()=>setOnboarded(true)}

                    >
                        OK
                    </Button>

                </div>
            }

            { onboarded &&
            <div>
            <LinearProgress style={{visibility:Object.keys(answers).length === 0?'visible':'hidden'}}/>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',

            }}>
                {
                    filters.map((filter, idx) => <Chip
                        label={filter.name}
                        color={idx === filterIndex ? "primary" : undefined}
                        onClick={()=>selectFilterIndex(idx)}
                        variant={idx === filterIndex ?  undefined: "outlined"}
                        style={{marginRight:10, marginBottom:10}}
                    />)
                }
                <Chip
                    label="Set Solution"
                    onClick={setSolutionClicked}
                    style={{marginRight:10,marginBottom:10}}
                />
                <Chip
                    label="Hide Inactive"
                    color={hideInactive ? "primary" : undefined}
                    onClick={()=>setHideInactive(!hideInactive)}
                    style={{marginBottom:10}}
                />

            </div>

            <AnswersTable answersSorted={currentFilter.result}
                          isCorrect={(ans) => ans !== '' && match(ans, solution)}
                          answerFieldName={answerFieldName}/>
            </div>
            }
        </Container>
    );
}