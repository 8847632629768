// From: https://www.w3schools.com/js/js_cookies.asp

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function rand10Digits() {
    return Array(10).fill().map(
        () => Math.floor(Math.random() * 10).toString()).join('');
}

export function getClientId() {
    let cookieName = "clientId";
    let id = getCookie(cookieName);
    if (id === "") {
        id = rand10Digits();
        setCookie(cookieName, id, 365);
    }
    return id;
}