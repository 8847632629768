import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from '@material-ui/core/styles';


export default function AnswersTable({answersSorted, isCorrect, answerFieldName}) {

    const theme = useTheme();

    let isHistorical = answerFieldName !== "answer";
    return (
        <Grid container justify="left" spacing={2}>
            {answersSorted.map((row, rowIdx) => {
                let boxColor = row.isActive ? theme.palette.primary.main : "#AAAAAA";
                // if (isHistorical) {
                //     boxColor = "#777777";
                // }
                return (<Grid key={rowIdx} item>
                    <div>
                        <Typography variant="body2" style={{color:boxColor}} >
                            {row.name}
                        </Typography>
                        <Typography variant="body2" style={{"whiteSpace": "pre-wrap",
                            backgroundColor:isCorrect(row[answerFieldName]) ? '#effceb' : 'white',
                            minWidth:'250px',
                            maxWidth:'750px',
                            minHeight:'40px',
                            maxHeight:'200px',
                            border:'2px solid ' + boxColor,
                            borderRadius: '5px',
                            padding:'8px',
                            overflowY: 'scroll'}}>

                            {
                                // Ace editor sometimes sends just \r for newline chars
                                row[answerFieldName]
                                .replaceAll('\r\n', '\n')
                                .replaceAll('\r', '\n')}
                        </Typography>



                    </div>

                </Grid>);
            })}
        </Grid>
    );

}