import firebase from "firebase";

export function toDocMap(querySnapshot) {
    let docMap = {};
    querySnapshot.forEach((doc) => {
        docMap[doc.id] = doc.data();
    });
    return docMap;
}

export function firestoreStudentsPath(sessionId) {
    return `sessions/${sessionId}/students`;
}

export function initFirebase() {
    let firebaseConfig = {
        apiKey: "AIzaSyBXMqtWQb2PVwanfsQhV7jLknnK2S6N-9s",
        authDomain: "answer-fox.firebaseapp.com",
        projectId: "answer-fox",
        storageBucket: "answer-fox.appspot.com",
        messagingSenderId: "291383410605",
        appId: "1:291383410605:web:06c2572f0d46e3a9d32b24"
    };

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

}

// Copied from firebase docs:
export async function deleteCollection(db, collectionPath, batchSize) {
    const collectionRef = db.collection(collectionPath);
    const query = collectionRef.orderBy('__name__').limit(batchSize);

    return new Promise((resolve, reject) => {
        deleteQueryBatch(db, query, resolve).catch(reject);
    });
}

async function deleteQueryBatch(db, query, resolve) {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    if (batchSize === 0) {
        // When there are no documents left, we are done
        resolve();
        return;
    }

    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
    });
    await batch.commit();

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
        deleteQueryBatch(db, query, resolve);
    });
}