import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from "react-router-dom";
import StudentApp from "./StudentApp";
import Setup from "./Setup";
import Dashboard from "./Dashboard";

ReactDOM.render(
        // <BrowserRouter>
        //     <App />
        // </BrowserRouter>,
    <HashRouter>
        <Switch>
            <Route path="/" component={Setup} exact />
            <Route path="/s" component={StudentApp} />
            <Route path="/dashboard" component={Dashboard} />
        </Switch>
    </HashRouter>,
    document.querySelector('#root'));

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


