import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import firebase from "firebase";
import {firestoreStudentsPath, toDocMap} from "./FirebaseUtil";
import {getClientId} from "./Cookie";
import {ErrorMessage} from "./ErrorMessage";
import Box from "@material-ui/core/Box";
import {FormControlLabel, FormGroup, Checkbox, NativeSelect} from "@material-ui/core";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import {useTheme} from "@material-ui/core/styles";


export default function StudentAnswer({sessionId, studentId, studentName}) {

    let [editorIsFocused, setEditorIsFocused] = React.useState(true);
    let [answer, setAnswer] = React.useState('');
    let [status, setStatus] = React.useState('Syncing with server...');
    let [locked, setLocked] = React.useState(false);
    let [aceMode, setAceMode] = React.useState('text');
    let clientId = getClientId();
    let createAnswer = (newAnswer) => ({answer: newAnswer, clientId, fromTeacher:false,
        lastClientActivity: firebase.firestore.FieldValue.serverTimestamp()});


    useEffect(async ()=> {
        let db = firebase.firestore();
        let ref = db.collection(firestoreStudentsPath(sessionId)).doc(studentId);
        let initialDoc = await ref.get();

        function isForeignClientId(doc) {

            if (!doc.exists) {
                // console.log('Received update but does not exist ', doc)
                return false;
            }
            if (doc.get('fromTeacher')) {
                // console.log('Received update but from teacher', doc)
                return false;
            }
            return doc.get('clientId') !== clientId;
        }


        let ans = createAnswer(answer);
        if (initialDoc.locked || !isForeignClientId(initialDoc)) {
            // We don't want to overwrite the existing answer
            delete ans.answer;
        }
        await ref.update(ans);


        ref
            .onSnapshot((doc) => {
                if (doc.metadata.hasPendingWrites) {
                    // Ignore local changes
                    return;
                }
                if (isForeignClientId(doc)) {
                    // We have been kicked off
                    setAnswer(null);
                    setStatus('Connected from another client');
                    return;
                }
                setAnswer(doc.get('answer'));
                setLocked(doc.get('locked'));
                setStatus('Sync complete');
            }, (error) => {
                setStatus("Error: "+ error);
            });

    }, []);





    async function sendAnswerToServer(newAnswer) {
        setStatus('Syncing with server...');
        try {
            let db = firebase.firestore();
            await db.collection(firestoreStudentsPath(sessionId))
                .doc(studentId).update(createAnswer(newAnswer));
            setStatus('Sync complete');
        } catch (error) {
            console.log(error);
            setStatus('Error' + error);
        }
    }

    if (answer === null) {
        return <ErrorMessage msg={status} />;
    }
    const theme = useTheme();
    let boxColor = locked || !editorIsFocused?  "#AAAAAA" : theme.palette.primary.main;
    return (
        <Container maxWidth="sm">
            <Typography variant="subtitle1" gutterBottom>
                {studentName}
            </Typography>
            <Typography variant="h4" gutterBottom>
                {locked ? 'Answer Locked' : 'My Answer'}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent='space-between'
                 style={{alignItems:'center', marginBottom:'5px'}} flexWrap="wrap">
                <Typography variant="body2">
                    {locked ? 'Your answer has been locked by the teacher' :
                        'Enter your answer below.  This text is visible to the teacher.'}
                </Typography>
                <FormGroup>
                    <NativeSelect
                        value={aceMode}
                        onChange={(e)=>setAceMode(e.target.value)}
                    >
                        <option value={'text'}>Plain Text</option>
                        <option value={'javascript'}>JavaScript</option>
                    </NativeSelect>

                </FormGroup>

            </Box>

            <div
                style={{border:'2px solid ' + boxColor, borderRadius:"5px", padding:"5px"}}
            >
            <AceEditor
                style={{width:"100%", height:"300px", backgroundColor:locked?"#eeeeee":undefined}}
                mode={aceMode}
                showGutter={true}
                highlightActiveLine={!locked}
                readOnly={locked}
                fontSize={14}
                value={answer}
                wrapEnabled={true}
                onFocus={()=>setEditorIsFocused(true)}
                onBlur={()=>setEditorIsFocused(false)}
                onChange={(newAnswer)=>{
                    if (locked) {
                        return;
                    }
                    setAnswer(newAnswer);
                    sendAnswerToServer(newAnswer);

                }}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                focus={true}
            />
            </div>


            <Typography variant="body2" gutterBottom style={{color:boxColor}}>
                {status}
            </Typography>

        </Container>
    );

}