import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";

export function ErrorMessage({msg}) {
    return (
        <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                Error
            </Typography>
            <Typography variant="body2" gutterBottom>
                {msg}
            </Typography>
        </Container>
    );
}