import React, {useEffect} from "react";
import LoadingMessage from "./LoadingMessage";
import firebase from "firebase";
import {firestoreStudentsPath, toDocMap} from "./FirebaseUtil";
import {ErrorMessage} from "./ErrorMessage";
import StudentList from "./StudentList";
import StudentAnswer from "./StudentAnswer";

export default function StudentApp({location}) {
    let sessionId = location.pathname.split('/').pop();
    console.log('Path:' , sessionId);

    const [state, setState] = React.useState(null);


    async function load() {
        let db = firebase.firestore();

        try {
            console.log('Fetching firebase student doc at ', firestoreStudentsPath(sessionId) );
            let querySnapshot = await db.collection(firestoreStudentsPath(sessionId)).get();
            if (querySnapshot.size === 0) {
                setState({error:"No student names found for session " + sessionId});
            } else {
                setState({studentsMap: toDocMap(querySnapshot)});
            }
        } catch (error) {
            console.error('Error getting student document', error);
            setState({error:"Error getting document:" + error});
        }


    }

    useEffect(()=>{load();}, []);


    function middleComponent() {
        if (state === null) {
            return <LoadingMessage/>;
        }
        else if (state.error) {
            return <ErrorMessage msg={state.error}/>;
        }
        else if (state.studentsMap) {
            return <StudentList studentsMap={state.studentsMap}
                                onComplete={(studentId)=>setState({studentId, studentName:state.studentsMap[studentId].name})}/>;
        } else {
            return <StudentAnswer sessionId={sessionId} studentId={state.studentId} studentName={state.studentName}/>;
        }
    }
    return (
         <div style={{paddingTop:'50px'}}>
             {middleComponent()}
        </div>
    );
}